import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Container, Col } from "react-bootstrap";
import { BsChevronUp } from "react-icons/bs";

import Header from "./header";

const Layout = ({ location, children }) => (
  <>
    <Header location={location} />
    <main>{children}</main>
    <footer className="footer">
      <Container>
        <Col>
          <div className="footer__copyright">
            <p>© {new Date().getFullYear()} ООО "Куптехник"</p>
          </div>
        </Col>
      </Container>
    </footer>
    <Link to="#" className="back-to-top">
      <BsChevronUp />
    </Link>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
