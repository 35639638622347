import React from "react";

const Logo = () => (
  <svg
    id="logo"
    viewBox="0 0 280.616 26.561"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M 0,0.84668 H 12.5638 V 4.3172 h -0.8315 c -0.8006,0 -1.2933,0.04194 -1.4781,0.12583 C 9.9585,4.57488 9.74295,4.76065 9.60758,5.00036 9.48418,5.24008 9.42249,5.68963 9.42249,6.349 v 6.6713 L 17.4596,5.77362 C 17.8166,5.45 18.0322,5.23415 18.1062,5.1262 18.1803,5.00634 18.2173,4.90445 18.2173,4.82055 18.2173,4.65275 18.1435,4.52693 17.9958,4.44303 17.8605,4.35914 17.5033,4.3172 16.9242,4.3172 H 15.8895 V 0.84668 H 27.5479 V 4.3172 h -0.3881 c -0.5419,0 -0.9176,0.02998 -1.127,0.08991 -0.2094,0.04794 -0.4681,0.1618 -0.776,0.34158 -0.2958,0.17978 -0.6837,0.47341 -1.164,0.88104 l -6.2264,5.25077 7.4273,9.764 c 0.6406,0.8392 1.1086,1.3367 1.4042,1.4925 0.3941,0.2158 0.9607,0.3237 1.6998,0.3237 h 0.4991 v 3.4704 H 15.8897 v -3.4704 h 0.5176 c 0.9485,0 1.5212,-0.0419 1.7183,-0.1258 0.2094,-0.0839 0.314,-0.1919 0.314,-0.3237 0,-0.1438 -0.1908,-0.4854 -0.5725,-1.0249 l -4.5267,-6.2758 -3.9169,3.2548 v 2.5355 c 0,0.6234 0.0617,1.0549 0.18509,1.2946 0.12299,0.2398 0.29534,0.4136 0.51701,0.5214 0.2341,0.0959 0.696,0.1438 1.3857,0.1438 h 1.0532 v 3.4705 H 7.16367e-4 V 22.4606 H 0.942983 c 0.726727,0 1.213267,-0.0539 1.459617,-0.1618 0.24638,-0.1199 0.4251,-0.3056 0.53615,-0.5573 0.09831,-0.2038 0.14747,-0.6952 0.14747,-1.4745 V 6.56492 C 3.08622,5.82166 3.03069,5.32413 2.91964,5.0724 2.80859,4.8207 2.63007,4.63492 2.38409,4.51507 2.1377,4.38323 1.69427,4.31737 1.0538,4.31737 H 7.16367e-4 Z"
      id="К"
    />
    <path
      d="M 57.8282,4.3172 H 57.3476 L 56.8861,4.29985 c -1.0593,0 -1.8785,0.56927 -2.4574,1.7081 L 46.2623,21.9039 c -1.5766,3.1045 -3.9169,4.6567 -7.0209,4.6567 -1.3302,0 -2.4696,-0.3296 -3.418,-0.989 -0.9485,-0.6593 -1.4227,-1.5523 -1.4227,-2.6792 0,-0.8152 0.271,-1.4865 0.813,-2.0139 0.5419,-0.5395 1.1332,-0.8094 1.7737,-0.8094 0.9361,0 1.7675,0.4257 2.4942,1.2768 0.3204,0.3716 0.6407,0.5573 0.9608,0.5573 0.4434,0 0.8068,-0.1377 1.0901,-0.4134 0.2834,-0.2877 0.6406,-0.8931 1.0716,-1.8162 L 43.1026,18.5768 34.4374,6.00742 C 33.9077,5.21621 33.4766,4.73671 33.144,4.56887 32.8236,4.40107 32.2016,4.3172 31.278,4.3172 V 0.84668 H 44.0818 V 4.3172 h -1.5335 c -0.6405,0 -0.9608,0.22781 -0.9608,0.68334 0,0.35957 0.1847,0.82711 0.5541,1.40262 l 4.1202,6.23964 3.1039,-6.43751 c 0.3328,-0.67131 0.4992,-1.11481 0.4992,-1.33059 0,-0.37155 -0.4065,-0.55732 -1.2194,-0.55732 H 47.3521 V 0.846857 h 10.476 z"
      id="У"
    />
    <path
      d="M 89.3888,0.84668 V 4.3172 h -1.0531 c -0.8992,0 -1.4658,0.12581 -1.6998,0.37751 -0.234,0.2517 -0.3511,0.87517 -0.3511,1.87021 V 20.159 c 0,1.043 0.1232,1.6904 0.3696,1.9421 0.2587,0.2397 0.9239,0.3595 1.9954,0.3595 h 0.7391 v 3.4705 H 76.7697 v -3.4705 h 0.8314 c 1.0346,0 1.6751,-0.1317 1.9215,-0.3954 0.2588,-0.2757 0.3881,-0.9111 0.3881,-1.9062 V 4.78462 H 70.5249 V 20.159 c 0,1.043 0.117,1.6904 0.351,1.9421 0.2341,0.2397 0.8684,0.3595 1.903,0.3595 h 0.8684 v 3.4705 H 61.0652 v -3.4705 h 0.8499 c 1.01,0 1.632,-0.1317 1.8661,-0.3954 0.2464,-0.2757 0.3696,-0.8451 0.3696,-1.7083 V 6.72669 c 0,-1.06692 -0.1232,-1.73233 -0.3696,-1.99605 C 63.5471,4.45497 63.0236,4.3172 62.2107,4.3172 H 61.0652 V 0.84668 Z"
      id="П"
    />
    <path
      d="m 96.3529,0 h 2.4388 l 0.2586,0.845108 H 113.591 L 113.905,0 h 2.457 l 3.197,8.79302 -3.437,1.16881 c -0.764,-1.46251 -1.312,-2.41548 -1.644,-2.85903 -0.653,-0.87514 -1.404,-1.56443 -2.254,-2.0679 -0.641,-0.38354 -1.275,-0.57538 -1.903,-0.57538 -0.296,0 -0.499,0.07192 -0.61,0.21574 -0.111,0.13184 -0.167,0.44349 -0.167,0.93502 V 20.0318 c 0,0.8511 0.031,1.3845 0.093,1.6003 0.062,0.2158 0.166,0.3896 0.314,0.5214 0.148,0.1199 0.351,0.2037 0.61,0.2517 0.258,0.0348 0.948,0.0522 2.069,0.0522 v 3.4705 h -12.564 v -3.4705 h 0.758 c 0.837,0 1.373,-0.048 1.607,-0.1439 0.234,-0.1079 0.413,-0.2876 0.536,-0.5393 0.123,-0.2637 0.185,-0.8031 0.185,-1.6183 V 5.41082 c 0,-0.41958 -0.05,-0.6834 -0.148,-0.79131 -0.099,-0.10787 -0.308,-0.16176 -0.629,-0.16176 -0.64,0 -1.354,0.23384 -2.143,0.70139 -0.7881,0.46752 -1.4901,1.12687 -2.106,1.978 -0.2464,0.33559 -0.7699,1.27661 -1.5705,2.8231 L 93.1743,8.79143 Z"
      id="Т"
    />
    <path
      d="m 123.458,0.84668 h 21.506 l 2.937,7.93004 -3.344,1.16881 C 143.991,8.65086 143.325,7.6078 142.562,6.8166 141.81,6.02539 140.973,5.50395 140.049,5.25222 139.138,4.98853 138.072,4.85665 136.853,4.85665 h -3.936 v 6.25775 h 1.294 c 0.973,0 1.626,-0.0659 1.958,-0.1977 0.345,-0.1439 0.591,-0.3597 0.739,-0.6475 0.148,-0.2996 0.222,-0.88703 0.222,-1.76221 V 7.55392 h 3.288 V 18.1634 h -3.288 v -1.0791 c 0,-0.7313 -0.093,-1.2647 -0.277,-1.6003 -0.173,-0.3356 -0.413,-0.5635 -0.721,-0.6834 -0.308,-0.1198 -0.992,-0.1796 -2.051,-0.1796 h -1.164 v 5.754 c 0,0.6114 0.031,0.9831 0.093,1.115 0.061,0.1318 0.178,0.2337 0.351,0.3056 0.184,0.072 0.572,0.1078 1.164,0.1078 h 2.161 c 1.614,0 2.975,-0.2217 4.084,-0.6653 1.108,-0.4555 2.094,-1.1808 2.956,-2.1758 0.874,-1.007 1.546,-2.1997 2.014,-3.5783 l 3.381,1.1328 -3.381,9.3145 h -22.282 v -3.4704 h 0.646 c 0.863,0 1.441,-0.0599 1.737,-0.1798 0.296,-0.1198 0.505,-0.3296 0.628,-0.6294 0.062,-0.1797 0.093,-0.6892 0.093,-1.5284 V 6.45714 c 0,-0.74322 -0.056,-1.22879 -0.167,-1.4566 -0.098,-0.22772 -0.259,-0.39548 -0.48,-0.50335 -0.222,-0.11985 -0.659,-0.17981 -1.312,-0.17981 h -1.146 z"
      id="Е"
    />
    <path
      d="m 152.612,0.84668 h 13.876 V 4.3172 h -1.072 c -0.567,0 -0.93,0.048 -1.09,0.14389 -0.148,0.0839 -0.222,0.22773 -0.222,0.43149 0,0.10787 0.031,0.22173 0.093,0.34158 0.062,0.10787 0.24,0.33551 0.536,0.68317 l 2.734,3.20079 3.067,-3.30875 c 0.394,-0.41958 0.591,-0.72516 0.591,-0.91697 0,-0.19177 -0.086,-0.3356 -0.258,-0.43149 -0.16,-0.09588 -0.536,-0.14389 -1.127,-0.14389 h -0.943 V 0.84668 h 11.566 v 3.47034 c -0.85,0.0232 -1.453,0.08316 -1.81,0.17982 -0.345,0.09588 -0.746,0.28773 -1.201,0.57538 -0.333,0.21574 -0.696,0.52739 -1.09,0.93502 l -6.855,6.59926 7.538,7.8221 c 0.825,0.8631 1.509,1.4206 2.051,1.6723 0.542,0.2397 1.281,0.3597 2.217,0.3597 v 3.4703 h -14.116 v -3.4703 h 1.294 c 0.603,0 0.979,-0.042 1.127,-0.1259 0.147,-0.0839 0.221,-0.2099 0.221,-0.3777 0,-0.2277 -0.203,-0.5753 -0.609,-1.0429 l -3.714,-4.1178 -3.972,4.1178 c -0.345,0.3715 -0.518,0.7013 -0.518,0.9891 0,0.1678 0.093,0.3056 0.278,0.4135 0.184,0.0959 0.56,0.1439 1.127,0.1439 h 0.997 v 3.4703 h -11.714 v -3.4703 c 0.813,0 1.386,-0.042 1.719,-0.1259 0.344,-0.0839 0.658,-0.2218 0.942,-0.4136 0.48,-0.3356 1.065,-0.8331 1.755,-1.4925 l 7.391,-7.1568 -7.04,-7.35447 c -0.665,-0.69532 -1.213,-1.13288 -1.644,-1.31271 -0.419,-0.19177 -1.127,-0.2876 -2.125,-0.2876 z"
      id="Х"
    />
    <path
      d="m 184.854,0.846857 h 12.582 V 4.31737 h -1.164 c -0.641,0 -1.078,0.06586 -1.312,0.1977 -0.234,0.11985 -0.4,0.29974 -0.499,0.53945 -0.099,0.22773 -0.148,0.73118 -0.148,1.5104 v 4.36958 h 9.386 V 6.24121 c 0,-0.57538 -0.037,-0.96502 -0.111,-1.16881 -0.099,-0.28766 -0.265,-0.48531 -0.5,-0.59326 -0.234,-0.10787 -0.751,-0.16194 -1.552,-0.16194 h -0.979 V 0.84668 h 12.619 V 4.3172 h -1.053 c -0.714,0 -1.188,0.05996 -1.422,0.17982 -0.234,0.10787 -0.401,0.27562 -0.499,0.50334 -0.087,0.21574 -0.13,0.73736 -0.13,1.56456 V 20.159 c 0,0.8392 0.055,1.3847 0.167,1.6364 0.123,0.2398 0.301,0.4134 0.535,0.5213 0.246,0.0959 0.801,0.1439 1.663,0.1439 h 0.739 v 3.4705 h -12.619 v -3.4705 h 0.831 c 0.776,0 1.3,-0.06 1.571,-0.1799 0.283,-0.1198 0.474,-0.2936 0.572,-0.5214 0.111,-0.2277 0.167,-0.7611 0.167,-1.6003 v -5.3046 h -9.386 v 5.3046 c 0,0.8392 0.037,1.3606 0.111,1.5644 0.123,0.2997 0.314,0.4975 0.573,0.5934 0.258,0.0959 0.782,0.1439 1.57,0.1439 h 0.869 v 3.4704 h -12.583 v -3.4704 h 0.85 c 0.715,0 1.214,-0.0599 1.497,-0.1798 0.283,-0.1198 0.474,-0.2876 0.572,-0.5033 0.111,-0.2158 0.167,-0.6894 0.167,-1.4207 V 6.72669 c 0,-0.85114 -0.056,-1.40256 -0.167,-1.65429 -0.098,-0.26369 -0.258,-0.45553 -0.48,-0.57538 C 187.07,4.37716 186.639,4.3172 185.998,4.3172 h -1.145 z"
      id="Н"
    />
    <path
      d="M 218.257,0.84668 H 230.84 V 4.3172 h -1.164 c -0.813,0 -1.343,0.11992 -1.589,0.35963 -0.247,0.23971 -0.37,0.86908 -0.37,1.88809 v 8.32538 l 9.386,-8.55918 c 0,-1.00698 -0.142,-1.59441 -0.425,-1.76225 -0.271,-0.1678 -0.85,-0.25167 -1.737,-0.25167 h -0.979 V 0.84668 h 12.619 V 4.3172 h -1.053 c -0.899,0 -1.466,0.12581 -1.7,0.37751 -0.234,0.2517 -0.351,0.875 -0.351,1.87003 V 20.159 c 0,1.043 0.123,1.6904 0.37,1.9421 0.258,0.2397 0.923,0.3595 1.995,0.3595 h 0.739 v 3.4705 h -12.619 v -3.4705 h 0.831 c 1.035,0 1.675,-0.1317 1.922,-0.3954 0.259,-0.2757 0.388,-0.9111 0.388,-1.9062 v -8.4335 l -9.386,8.4335 c 0.012,0.8512 0.055,1.3906 0.13,1.6184 0.074,0.2277 0.24,0.4015 0.499,0.5214 0.258,0.1079 0.8,0.1618 1.626,0.1618 h 0.868 v 3.4705 h -12.582 v -3.4705 h 0.85 c 1.01,0 1.632,-0.1319 1.866,-0.3956 0.246,-0.2757 0.369,-0.8451 0.369,-1.7083 V 6.72651 c 0,-1.06691 -0.123,-1.73215 -0.369,-1.99587 C 220.74,4.45497 220.216,4.3172 219.403,4.3172 h -1.145 z"
      id="И"
    />
    <path
      d="m 251.718,0.84668 h 12.564 V 4.3172 h -0.831 c -0.801,0 -1.294,0.04194 -1.478,0.12583 -0.296,0.13185 -0.512,0.31762 -0.647,0.55733 -0.123,0.23972 -0.185,0.68927 -0.185,1.34864 v 6.6713 l 8.037,-7.24668 c 0.358,-0.32362 0.573,-0.53947 0.647,-0.64742 0.074,-0.11986 0.111,-0.22175 0.111,-0.30565 0,-0.1678 -0.074,-0.29362 -0.221,-0.37752 -0.136,-0.08389 -0.493,-0.12583 -1.072,-0.12583 h -1.035 V 0.84668 h 11.659 V 4.3172 h -0.388 c -0.542,0 -0.918,0.02998 -1.127,0.08991 -0.21,0.04794 -0.468,0.1618 -0.776,0.34158 -0.296,0.17978 -0.684,0.47341 -1.164,0.88104 l -6.227,5.25077 7.428,9.764 c 0.64,0.8392 1.108,1.3367 1.404,1.4925 0.394,0.2158 0.961,0.3237 1.7,0.3237 h 0.499 v 3.4704 h -13.008 v -3.4704 h 0.517 c 0.948,0 1.521,-0.0419 1.718,-0.1258 0.209,-0.0839 0.314,-0.1919 0.314,-0.3237 0,-0.1438 -0.191,-0.4854 -0.572,-1.0249 l -4.527,-6.2758 -3.917,3.2548 v 2.5353 c 0,0.6234 0.062,1.0551 0.185,1.2948 0.123,0.2398 0.295,0.4136 0.517,0.5214 0.234,0.0959 0.695,0.1438 1.385,0.1438 h 1.053 v 3.4705 h -12.563 v -3.4705 h 0.942 c 0.727,0 1.213,-0.0539 1.46,-0.1618 0.246,-0.1199 0.424,-0.3056 0.535,-0.5573 0.099,-0.2038 0.148,-0.6952 0.148,-1.4745 V 6.56492 c 0,-0.74326 -0.055,-1.24079 -0.166,-1.49252 C 254.526,4.8207 254.347,4.63492 254.1,4.51507 253.854,4.38323 253.411,4.3172 252.77,4.3172 h -1.053 z"
      id="К"
    />
  </svg>
);

export default Logo;
