import React from "react";
import { withPrefix } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Navbar, Nav } from "react-bootstrap";

import Logo from "./logo";

const Header = ({ location }) => {
  const isHomepage = location.pathname === withPrefix("/");
  let linkPrefix;
  if (isHomepage) {
    linkPrefix = "";
  } else {
    linkPrefix = withPrefix("/");
  }
  return (
    <header>
      <Container className="mainMenu" fluid>
        <Row>
          <Col lg="12">
            <Navbar expand="lg" fixed="top">
              <div className="mainMenu__logo">
                <Navbar.Brand href={withPrefix("/")}>
                  <Logo />
                </Navbar.Brand>
              </div>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
              </Navbar.Toggle>
              <Navbar.Collapse>
                <Nav className="ms-auto">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <Nav.Link href={linkPrefix + "#main"}>Главная</Nav.Link>
                    </li>
                    <li className="nav-item dev">
                      <Nav.Link href={linkPrefix + "#about"}>
                        О&nbsp;нас
                      </Nav.Link>
                    </li>
                    <li className="nav-item dev">
                      <Nav.Link href={linkPrefix + "#services"}>
                        Услуги
                      </Nav.Link>
                    </li>
                    <li className="nav-item dev">
                      <Nav.Link href={linkPrefix + "#projects"}>
                        Проекты
                      </Nav.Link>
                    </li>
                    <li className="nav-item dev">
                      <Nav.Link href={linkPrefix + "#partners"}>
                        Партнеры
                      </Nav.Link>
                    </li>
                    <li className="nav-item dev">
                      <Nav.Link href={linkPrefix + "#clients"}>
                        Клиенты
                      </Nav.Link>
                    </li>
                    <li className="nav-item">
                      <Nav.Link href={linkPrefix + "#contact"}>
                        Контакты
                      </Nav.Link>
                    </li>
                  </ul>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
